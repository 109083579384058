<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        lg="1"
        class=".d-none hidden-md-and-down"
      />
      <v-col
        cols="12"
        lg="10"
        md="12"
        sm="12"
      >
        <div>
          <v-row>
            <v-col
              cols="12"
            >
              <v-card>
                <v-card-text>
                  {{ $t('registerUserComplete') }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col
        cols="12"
        lg="1"
        class=".d-none hidden-md-and-down"
      />
    </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'RegisterUserComplete',
    data: function () {
      return {
        showErrorDialog: false,
        errorMessage: '',
        environment: process.env.VUE_APP_ENVIRONMENT,
        modelProcessing: false,
      }
    },
  }
</script>
